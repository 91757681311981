<template>
	<div class="table">
		<div class="header">
		<!-- 	<div class="duoxuan">
				<div class=""></div>
			</div> -->
			<div class="list">
				<div class="item" :style="{'width':item.width+'%'}" v-for="(item, index) in columns" :key="index">
					{{item.title}}
				</div>
			</div>
		</div>
		<div class="table_body">
			<div class="body" v-for="item in list" :key="item.treeId">
			<!-- 	<div class="duoxuan">
					<div class="">
						
					</div>
				</div> -->
				<div class="list">
					<div class="item" style="width:15%;">
						{{item.title}}
					</div>
					<div class="item" style="width:10% ;">
						 {{ item.icon }}
					</div>
					<div class="item" style="width:10% ;">
						{{ item.menuSort }}
					</div>
					<div class="item" style="width:15% ;">
						{{item.path}} 
					</div>
					<div class="item" style="width:10% ;">
						{{item.path}} 
					</div>
					<div class="item" style="width:10% ;">
						<!-- {{item.hidden}} -->
						{{item.hidden ? "是" : "否" }}
					</div>
					<div class="item" style="width:10% ;">
						{{item.createTime}} 
					</div>
					<div class="item" style="width:10% ;">
						<a-button type="primary" @click="handleSee(item)">查看</a-button>
						<a-button type="primary" style="margin: 0 20px;" @click="showChangeModal(item)">修改</a-button>
            <a-popconfirm
              title="确认删除?"
              ok-text="确认"
              cancel-text="取消"
              @confirm="deleteData(item)"
              @cancel="cancel"
            >
						  <a-button type="danger">删除</a-button>
            </a-popconfirm>
					</div>
					
				</div>
				
			</div>
			
		</div>
    <!-- 修改菜单 -->
    <MenuChange
        :disabledChange="disabledChange"
        :OneMenuData="OneMenuData"
        @getChangeModal="getChangeModal"
        :tests="tests"
    ></MenuChange>
	</div>
</template>

<script>
import MenuChange from "./MenuChange";

	export default{
    inject: ['reload'],
		props:{
			list:Array
		},
    components:{
      MenuChange,
  },
		data(){
			return {
				Title:'',
				visible:false,
				columns:[
				  {
					   title: '菜单名称',
				    dataIndex: 'name',
				    key: 'name',
					  width:'15'
				  },
				  {
				    title: '图标',
				    dataIndex: 'age',
				    key: 'age',
					width:'10'
				  },
				  {
				    title: '顺序',
				    dataIndex: 'address',
				    key: 'address',
					width:'10'
				  },
				  {
				    title: '路径',
				    key: 'action',
					  dataIndex: 'tags',
					  width:'15'
				  },
				  {
				    title: '重定向',
				    key: 'action',
				  					  dataIndex: 'tags',
				  					  width:'10'
				  },
				  {
				    title: '可见',
				    key: 'action',
				  					  dataIndex: 'tags',
				  					  width:'10'
				  },
				{
				  title: '创建时间',
				  key: 'action',
									  dataIndex: 'tags',
									  width:'10'
				},
				{
				  title: '操作',
				  key: 'action',
									  dataIndex: 'tags',
									  width:'10'
				},
				],
			  OneMenuData:{},
        disabledChange:false,
        tests: '',
			}
		},
		methods:{
			handleDetail(){
				this.$router.push('/except/detail')
			},
			handleCancel(){
				this.visible=false
			},
      // 修改菜单
			showChangeModal(record){
        this.tests = '修改菜单'
				this.disabledChange = true
        this.OneMenuData = record
			},
      getChangeModal(){
       this.disabledChange = false
    },
    // 确认删除
		async	deleteData(e){
        let menuId = e.menuId
				const res = await this.$axios.delete(this.api + '/menu/delete/' + menuId)
        if(res.code !== 200) {
          this.$message.info('删除失败,请联系管理员')
        } else {
          this.$message.success('删除成功!')
          this.reload()
        }
			},
      // 取消删除
      cancel() {
        this.$message.warning('已取消删除')
      },
			handleSee(record){
        this.tests = '查看菜单'
        this.OneMenuData = record
				this.Title=record.name
				this.disabledChange=true
			}
		}
	}
</script>

<style scoped>
	.table{
		width: 100%;
		height: 100%;
		color: #fff;
		/* background-color: red; */
	}
	.yuan{
		width: 5px;
		height: 5px;
		border-radius: 50%;
		background-color: red;
		margin-right: 5px;
	}
	.item1>div{
		display: flex;
	}
	.yi>div,
	.wei>div{
		margin-right:10px;
		width: 4em;
	}
	.wei>div:hover{
		cursor: pointer;
	}
	.duoxuan{
		width: 40px;
		height: 100%;
		display: flex;
		align-items: center;
		justify-content: center;
		
	}
	.duoxuan>div{
		width: 10px;
		height: 10px;
		border: 1px solid #ccc;
		background-color: #fff;
	}
	.header{
		display:flex;
		height: 50px;
		background-color:#1f325e ;
		border-bottom: 1px solid #005ea1;
		/* align-items: center; */
	}
	.header .list{
		background-color:#1f325e ;
		width: calc(100% - 40px);
		display: flex;
		align-items: center;
	}
	.table_body{
		height: calc(100% - 50px);
		overflow-y: auto;
	}
	.table_body .body{
		display:flex;
		min-height: 50px;
		border-bottom: 1px solid #005ea1;
	}
	.table_body .list{
		width: calc(100% - 40px);
		display: flex;
		align-items: center;
	}
	.item{
		display: flex;
		align-items: center;
		padding: 0 20px;
	}
	.model_item{
		display: flex;
		line-height: 3em;
		
	}
/* 	.model_item1{
		width:100%;
		justify-content: center;
	} */
</style>
