<template>
  <div class="main">
	  <div class="mianbao">
	  	<div class="breadcrumb">
	  		<span style="color: #fff;">
	  			系统设置>
	  		</span>
	  		<span style="color:#016ae0;">
	  			菜单管理
	  		</span>
	  	</div>
	  </div>
    <div class="header">
      <a-button @click="showModal" type="primary" icon="plus" style="margin-left: 20px;">新增</a-button>
    </div>
    <div class="center">
    <vue-scroll :ops="ops" style="width:100%;">
		  <Menutable :list="menuTreeList" />
    </vue-scroll>
    </div>
    <menuAdd :disabled="disabled" @changeState="getChangeState"></menuAdd>
    <MenuChange :OneMenuData="OneMenuData" :disabledChange="disabledChange" @getChangeModal="getChangeModal"></MenuChange>
  </div>
</template>

<script>
import menuAdd from "./modal/menuAdd";
import MenuChange from "./modal/MenuChange";
import Menutable from './modal/menutable.vue'
const columns = [
  {
    title: '菜单名称',
    dataIndex: 'name',
  },
  {
    title: '图标',
    dataIndex: 'icon',
  },
  {
    title: '顺序',
    dataIndex: 'm_order',
  },
  {
    title: '路径',
    dataIndex: 'path',
  },
  {
    title: '重定向',
    dataIndex: 'redirect',
  },
  {
    title: '可见',
    dataIndex: 'hidden',
    scopedSlots: { customRender: 'hidden' },
  },
  {
    title: '创建时间',
    dataIndex: 'create_time',
    scopedSlots: { customRender: 'create_time' },
  },
  {
    title: '操作',
    scopedSlots: {customRender: 'action'},
    key: 'action',
    width: 400
  },
];

export default {
  name: "menuManager",
  components:{
    menuAdd,
    MenuChange,
	Menutable
  },
  data() {
    return {
      // 滚动条
      ops: {
        vuescroll: {},
        scrollPanel: {},
        rail: {
          keepShow: true
        },
        bar: {
          hoverStyle: true,
          onlyShowBarOnScroll: false, //是否只有滚动的时候才显示滚动条
          background: "#F5F5F5",//滚动条颜色
          opacity: 0.5,//滚动条透明度
          "overflow-y": "hidden"
        }
      },
      columns,
      disabled:false,
      disabledChange:false,
      OneMenuData:{},
	    menuTreeList:[]
    };
  },
  mounted() {
	   this.getMenuLists()
  },
  methods:{
    // 菜单列表
	  getMenuLists(){
		  this.$axios.get(this.api+'/menu/show',{
		  }).then(res=>{
			  this.menuTreeList=res.data
		  })
	  },
    setMenuTree(list,parent_id=null){
      return list.filter(item=>item.parent_id===parent_id).
      sort((a,b)=> a.m_order - b.m_order).
      map(
          item=>{
            if(this.setMenuTree(list, item.id).length > 0){
              return {...item, key:item.id,children: this.setMenuTree(list, item.id)}
            }else{
              return {...item,key:item.id,}
            }
          }
      )
    },
    showModal(){
      this.disabled = true
    },
    getChangeState(){
      this.disabled = false
    },
    //编辑
    showChangeModal(record){
      console.log(record);
      this.disabledChange=true
      this.OneMenuData = record
    },
    getChangeModal(){
      this.disabledChange=false
    }
  }
}
</script>

<style scoped>
  .main{
    height: 100%;
    padding: 15px;
	  background: url(../../assets/bg01.jpg) no-repeat;
	  background-size: 100% 100%;
  }
  .mianbao{
  	height:40px;
  	width: 100%;
  	display: flex;
  	justify-content: space-between;
  	align-items: center;
  	/* background-color: yellow; */
  }
  .header{
    width: 100%;
   border: 1px solid #016ae0;
   border-radius: 8px;
    height: 60px;
    text-align: left;
    padding: 15px;
  }
  .center{
    height: calc(100% - 120px);
  border: 1px solid #016ae0;
  border-radius: 8px;
    margin-top: 20px;
    padding: 15px;
    border-radius: 15px;
  }
</style>
