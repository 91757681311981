<template>
  <a-modal
      title="新建菜单"
      :visible="disabled"
      :maskClosable="false"
      okText="提交"
      cancelText="取消"
      @ok="handleOk"
      @cancel="handleCancel"
  >
    <div class="formDiv">
      <p>菜单名称：</p>
      <a-input v-model="menuForm.title" placeholder="必填"/>
    </div>
    <div class="formDiv">
      <p>菜单路径：</p>
      <a-input v-model="menuForm.path" placeholder="必填"/>
    </div>
    <div class="formDiv">
      <p>重定向地址：</p>
      <a-input v-model="menuForm.path"/>
    </div>
    <div class="formDiv">
      <p>菜单类型：</p>
      <a-input v-model="menuForm.type" placeholder="必填"/>
    </div>
    <div class="formDiv">
      <p>顺序：</p>
      <a-input v-model="menuForm.menuSort" placeholder="必填"/>
    </div>
    <div class="formDiv">
      <p>菜单图标：</p>
      <a-input v-model="menuForm.icon"/>
    </div>
    <div class="formDiv">
      <p>菜单标志：</p>
      <a-input v-model="menuForm.permission"/>
    </div>
    <!-- <div class="formDiv">
      <p>菜单代号：</p>
      <a-input v-model="menuForm.en_name" placeholder="必填"/>
    </div> -->
    <div class="formDiv">
      <p>上级菜单：</p>
      <a-select style="width: 200px;" mode="default" placeholder="请选择上级菜单" @change="handleChange">
        <a-select-option v-for="(a, index) in menuList" :key="index">
          {{ a.name }}
        </a-select-option>
      </a-select>
    </div>
    <div class="formDiv">
      <p>是否显示：</p>
      <a-switch checked-children="开" un-checked-children="关" default-checked @change="changeState"/>
    </div>
  </a-modal>
</template>

<script>
export default {
  inject: ['reload'],
  name: "menuAdd",
  data(){
    return{
      menuForm:{
        hidden:1,
        pid:''
      }
    }
  },
  computed:{
    menuList(){
      return this.$store.state.menu.menuList
    }
  },
  props:["disabled"],

  methods:{
    handleChange(value) {
      console.log(value);
      this.menuForm.pid = value;
    },
    handleOk() {
      if(!this.menuForm.title){
        this.$message.error('菜单名称不准为空')
      }else if(!this.menuForm.path){
        this.$message.error('菜单路径不准为空')
      }else if(!this.menuForm.type){
        this.$message.error('菜单类型不准为空')
      }else{
        this.$axios.post(this.api+'/menu/add',this.menuForm).then(
            res=>{
              // console.log(res);
              if(res.code === 200){
                this.$message.success('添加成功')
                this.$emit('changeState',false)
                this.$store.dispatch('getMenuLists',window.localStorage.getItem('username'))
                this.menuForm={
                  hidden:1,
                  pid:''
                }
                this.reload()
              }
            }
        )
      }
    },
    handleCancel(e) {
      this.$emit('changeState',false)
    },
    changeState(e){
      console.log(e);
      if(e){
        this.menuForm.hidden = 1
      }else{
        this.menuForm.hidden = 0
      }
    },
  }
}
</script>

<style scoped>
  .formDiv{
    display: flex;
  }
  .formDiv > input{
    width: 200px;
  }
  .formDiv > p{
    width: 6rem;
    line-height: 30px;
  }
</style>
