<template>
  <a-modal
      :title="tests"
      :visible="disabledChange"
      :maskClosable="false"
      okText="确认"
      cancelText="取消"
      @ok="handleOk"
      @cancel="handleCancel"
  >
    <div class="formDiv">
      <p>菜单名称：</p>
      <a-input v-model="OneMenuData.title" placeholder="必填"/>
    </div>
    <div class="formDiv">
      <p>菜单路径：</p>
      <a-input v-model="OneMenuData.path" placeholder="必填"/>
    </div>
    <div class="formDiv">
      <p>重定向地址：</p>
      <a-input v-model="OneMenuData.path"/>
    </div>
    <div class="formDiv">
      <p>菜单类型：</p>
      <a-input v-model="OneMenuData.type" placeholder="必填"/>
    </div>
    <div class="formDiv">
      <p>顺序：</p>
      <a-input v-model="OneMenuData.menuSort" placeholder="必填"/>
    </div>
    <div class="formDiv">
      <p>菜单图标：</p>
      <a-input v-model="OneMenuData.icon"/>
    </div>
    <div class="formDiv">
      <p>菜单标志：</p>
      <a-input v-model="OneMenuData.permission"/>
    </div>
    <!-- <div class="formDiv">
      <p>菜单代号：</p>
      <a-input v-model="OneMenuData.en_name" placeholder="必填"/>
    </div> -->
    <div class="formDiv">
      <p>上级菜单：</p>
      <a-select style="width: 200px;" mode="default" placeholder="请选择上级菜单" @change="handleChange" v-model="OneMenuData.pid">
        <a-select-option v-for="(a, index) in menuList" :key="index">
          {{ a.name }}
        </a-select-option>
      </a-select>
    </div>
    <div class="formDiv">
      <p>是否显示：</p>
      <a-switch v-model="OneMenuData.hidden" checked-children="开" un-checked-children="关" default-checked @change="changeState"/>
    </div>
  </a-modal>
</template>

<script>
export default {
  // name: "MenuChange",
  inject: ['reload'],
  data(){
    return{
      menuForm:{
        hidden:1,
        pid:''
      }
    }
  },
  computed:{
    menuList(){
      return this.$store.state.menu.menuList
    }
  },
  props:["disabledChange","OneMenuData","tests"],
  methods:{
    handleChange(value) {
      // console.log(value);
      this.OneMenuData.pid = value;
    },
    handleOk() {
      if(this.tests === '查看菜单') {
        this.$emit('getChangeModal',false)
      } else {
      if(!this.OneMenuData.title){
        this.$message.error('菜单名称不准为空')
      }else if(!this.OneMenuData.path){
        this.$message.error('菜单路径不准为空')
      }else if(!this.OneMenuData.type){
        this.$message.error('菜单类型不准为空')
      }else{
        this.$axios.put(this.api+'/menu/update',this.OneMenuData).then(
            res=>{
              // console.log(res);
              if(res.code === 200){
                this.$message.success('修改成功')
                this.$emit('getChangeModal',false)
                this.$store.dispatch('getMenuLists',window.localStorage.getItem('username'))
                this.reload()
              }
            }
        )
      }
     }
    },
    handleCancel(e) {
      this.$emit('getChangeModal',false)
    },
    changeState(e){
      if(e){
        this.OneMenuData.hidden = true
      }else{
        this.OneMenuData.hidden = false
      }
    },
  }
}
</script>

<style scoped>
.formDiv{
  display: flex;
}
.formDiv > input{
  width: 200px;
}
.formDiv > p{
  width: 6rem;
  line-height: 30px;
}
</style>
